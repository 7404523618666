import { Upload,message, notification } from 'antd';
import React, { useEffect, useState } from 'react'
import API_URL from '../../../api/url';

const FileUpload = ({ file, setFile, handleUpload }) => {
    const [fileList, setFileList] = useState([]);
    const beforeUpload = (file) => {
        const isWebp = file.type === 'image/webp'
        if (!isWebp) {
            notification["error"]({
                message: "Please Upload Images in WebP Format Only"
            });
        }
        const isLt400kb = file.size / 1024 / 1024 < .3;
        if (!isLt400kb) {
            notification["error"]({
                message: "Image must smaller than 300kb!"
            });
        }
        return isWebp && isLt400kb;
      };
    const onPreview = async (file) => {
        let src = file.url;
        window.open(src, "_blank");
    };
    const onRemove = () => {
        setFile("");
        setFileList([]);
    }
    useEffect(() => {
        if (file) {
            setFileList([{
                crossOrigin: "anonymous",
                uid: '-1',
                name: "BannerPath",
                status: 'done',
                url: API_URL.S3_BANNER_BASE + file,
                thumbUrl: API_URL.S3_BANNER_BASE + file
            }])
        }
    }, [file])
    return (
        <Upload
            className='upload_img'
            accept="image/webp"
            multiple={false}
            listType="picture-card"
            onPreview={onPreview}
            onRemove={onRemove}
            beforeUpload={beforeUpload}
            customRequest={({ file }) =>
                handleUpload(file)
            }
            fileList={fileList}
        >
            {fileList.length < 1 && '+ Upload'}
        </Upload>
    )
}

export default FileUpload

