import axios from "axios";
import { notification } from "antd";
import { useNavigate } from 'react-router-dom'
import { DeleteToken } from "../utils";
import { GetToken } from "../utils";
import URL from "./url";

const Api = axios.create({
  baseURL: URL.API_BASEURL
})
Api.interceptors.request.use((request) => {
  if(!request?.headers?.["token"]) {
    request.headers["token"] = GetToken() ? `${GetToken()}` : ""
  }
  return request
})
Api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // const navigate = useNavigate();

    /* if (error?.response?.status === 401) {
    } else if (error?.response?.status === 403) {
      toast.error("Access Forbidden !");
    } else if (error?.response?.status === 500) {
      toast.error("Internal Server Error !");
    } */
    if (error?.response?.status === 401) {
 
    //   notification["error"]({
    //     message: error.response?.data?.Message || error.message
    // });
    // navigate('/login');
    console.log("token delete")
    DeleteToken();
    window.location.href = '/login'
    // navigate("/login", { replace: true });
    } 
    return Promise.reject(error);
  }
);
export default Api;
