import { Col, Row, Table, Tooltip,Typography,Button,Modal } from 'antd';
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import HeadingBack from '../../../component/HeadingBack';
import SvgIcon from '../../../const/SvgIcon';
import { usePermission } from '../../../hooks/usePermission';
import { FaqServices } from '../../../redux/Services';
import { LeadPageServices } from '../../../redux/Services';
import LeadPageFilter from './LeadPageFilter';
import { FileImageTwoTone } from '@ant-design/icons';
import API_URL from '../../../api/url';

const ManagePageItem = () => {
    const permission = usePermission("Lead Page Management", "List");
    const url = API_URL.S3_BANNER_BASE
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const leadPage = useSelector(state => state.lead.leadPageList || []);
    const totalLeadPage = useSelector(state => state.lead.totalLeadPageList || "");
    // console.log(totalLeadPage, "totalLeadPageList")
    const [page, setPage] = useState(1);
    const [filter, setFilter] = useState({});
    const couponCategoryList = useSelector(state => state.coupon.couponCategoryList || []);
    const zoneList = useSelector((state) => state.lead.zoneList || []);
    const { mutate: fetchZoneList } = useMutation((data) => dispatch(LeadPageServices.fetchZoneListApi(data)));
    const { mutate: leadPageList, isLoading } = useMutation((data) => dispatch(LeadPageServices.fetchLeadPageApi({Page:page,...data})));
// console.log(couponCategoryList)
const [modal2Open, setModal2Open] = useState(false);
const [raw, setRaw] = useState({});

    useEffect(() => {
        fetchZoneList({ Active: "1" });
        return () => { }
    }, [])
    useEffect(() => {
        leadPageList(filter)
        return () => { }
    }, [filter,page])
    // console.log(couponList)
    // console.log(filter)
    const columns = [
        {
            title: 'S.No.',
            dataIndex: 'sno',
            key: 'sno',
            className: 'table_first_col',
            render: (value, item, index) => (page - 1) * 20 + index + 1
        },
        {
            title: 'Zone/Region',
            dataIndex: 'Name',
            key: 'Name',
            className: 'text-capitalize',

            render: (value, item, index) => {
                return (
                    <span>{item?.Zone?.Name}</span>
                )
            }
        },
        {
            title: 'Test Code',
            dataIndex: 'PageTestCode',
            key: 'PageTestCode',
            className: 'table_action_col',
            render: (value, item, index) => {
                return (
                    <span>{item?.PageTestCode}</span>
                )
            }
        },
        {
            title: 'Price City',
            dataIndex: 'PriceCityId',
            key: 'PriceCityId',
            render: (value, item, index) => {
                return (
                    <span>{item?.PriceCityId}</span>
                )
            }
        },
        {
            title: 'Page Suffix',
            dataIndex: 'PageSuffix',
            key: 'PageSuffix',
            render: (value, item, index) => {
                return (
                    <span>{item?.PageSuffix}</span>
                )
            }
        },
        {
            title: 'Page Slug',
            dataIndex: 'PageSlug',
            key: 'PageSlug',
            render: (value, item, index) => {
                return (
                    <span>{item?.PageSlug}</span>
                )
            }
        },
        {
            title: 'Related Test Codes',
            dataIndex: 'RelatedTestCode',
            key: 'RelatedTestCode',
          className: "lead-w",
            render: (value, item, index) => {
                return (
                    <span>{item?.RelatedTestCode}</span>
                )
            }
        },
        {
            title: 'Cities',
            dataIndex: 'PageCities',
            key: 'PageCities',
            className: "lead-w"

        },
        {
            title: 'Banner Image',
            dataIndex: 'BannerPath',
            key: 'BannerPath',
            className: 'table_status_col',
            // render: (value, item, index) => <span>{item?.Banner && <Typography.Link href={item?.Banner} target='_blank'><FileImageTwoTone style={{ fontSize: "30px" }} /></Typography.Link>}</span>
            render: (value, item, index) => <span>{item?.BannerPath && <Button className="btl_img_class" type="primary" onClick={() => { setRaw(item?.BannerPath ? `${url}${item.BannerPath}` : "{}"); setModal2Open(true) }}><img className="btl_img" src={`${url}${item?.BannerPath}`} crossorigin="anonymous" /></Button>}</span>
        },
        
        {
            title: 'Status',
            dataIndex: 'Status',
            key: 'Status',
            className: 'table_status_col',
            render: (value, item, index) => {
                return (
                    <span className={item?.Status ? "status_active" : "status_inactive"}>{item?.Status ? "Active" : "Inactive"}</span>
                )
            }
        },
        {
            title: 'Actions',
            dataIndex: 'action',
            key: 'action',
            className: 'table_action_col',
            render: (value, item, index) => {
                return (
                    <Tooltip title="Edit">
                        <span className='cursor-pointer' onClick={() => navigate(`/manage-page/edit/${item?.Id}`)}><SvgIcon.Edit className="scale-icon edit-icon" /></span>
                    </Tooltip>
                )
            }
        },
    ];
    return (
        <div>
            <Row gutter={[10, 10]} align={"middle"}>
                <Col lg={22} md={22} sm={24} xs={24}>
                    <HeadingBack title={"Pages"} subHeading={`Total ${totalLeadPage || 0} Lead Pages`} />
                </Col>
                <Col lg={19} md={12} sm={24} xs={24}>
                    <LeadPageFilter filter={filter} setFilter={setFilter} setPage={setPage} zoneList={zoneList} />
                </Col>
                <Col lg={2} md={24} sm={24} xs={24}>
                    <div className='flex justify-content-end align-items-center'>
                        <Tooltip title="Add"><span className='jop_btn' onClick={() => navigate("/manage-page/add")}>Add<SvgIcon.Add width={40} height={40} className="cursor-pointer" /></span></Tooltip>
                    </div>
                </Col>
            </Row>
            <div className='mt-10'>
                <Table
                    rowKey='Id'
                    className={isLoading ? 'table antd_table no_data_found_hide' : 'table antd_table'}
                    bordered
                    columns={columns}
                    dataSource={Array.isArray(leadPage) && leadPage?.length > 0 ? leadPage : []}
                    pagination={{
                        current: page,
                        pageSize: 20,
                        total: totalLeadPage,
                        onChange(current, page) {
                            setPage(current);
                        },
                        hideOnSinglePage: false,
                    }}
                />
            </div>
            <Modal
                className='btl_modal'
                centered
                open={modal2Open}
                onOk={() => setModal2Open(false)}
                onCancel={() => setModal2Open(false)}
                footer={null}
            >
                <img src={(raw)} crossOrigin='anymouse' />
            </Modal>
        </div>
    )
}

export default ManagePageItem