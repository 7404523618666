import { Col, Input, Row, Select } from "antd";
import React, { useState, useEffect } from "react";
const { Option } = Select;
const LeadPageFilter = ({ filter, setFilter, setPage, zoneList }) => {
  const queryParams = new URLSearchParams(window.location.search);
  const PageTestCode = queryParams.get("PageTestCode");
  const [values, setValues] = useState({
    Status: "",
    Page: 1,
    PageSlug: "",
    PageTestCode: "",
    PriceCityId: "",
    ZoneId: "",
  });
  const handleChange = (name, value) => {
    setValues({ ...values, [name]: value });
  };
  const handleFilter = () => {
    setFilter({ ...filter, ...values });
    setPage(1);
  };
  const handleFilterReset = () => {
    setFilter({});
    setValues({
      ...values,
      Status: "",
      PageTestCode: "",
      PageSlug: "",
      ZoneId: "",
    });
    setPage(1);
  };

  useEffect(() => {
    if (PageTestCode) {
      handleChange("TestId", PageTestCode);
    }
  }, [PageTestCode]);

  return (
    <div className="table_filter">
      <Row gutter={[10, 10]}>
        <Col md={4} sm={12} xs={24}>
          <Select
            className="text-capitalize w-100"
            placeholder="Select Zone/Region"
            autoComplete="new-off"
            onChange={(value) => handleChange("ZoneId", value)}
            value={values?.ZoneId}
          >
            <Option className="text-capitalize" value="">
              Select Zone/Region
            </Option>
            {zoneList?.length > 0 &&
              zoneList?.map((item, idx) => (
                <Option key={idx} className="text-capitalize" value={item.Id}>
                  {item.Name}
                </Option>
              ))}
          </Select>
        </Col>
        {/* <Col md={4} sm={12} xs={24}>
                    <Input
                        type="text"
                        autoComplete="new-off"
                        className='form-control'
                        onChange={(e) => handleChange("TestId", e.target.value)}
                        placeholder='Label'
                        value={values?.TestId}
                    />
                </Col> */}
        <Col md={4} sm={12} xs={24}>
          <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChange("PageTestCode", e.target.value)}
            placeholder="Test Code"
            value={values?.PageTestCode}
          />
        </Col>
        <Col md={6} sm={12} xs={24}>
          <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChange("PageSlug", e.target.value)}
            placeholder="Page Slug"
            value={values?.PageSlug}
          />
        </Col>
        <Col md={4} sm={12} xs={24}>
          <Select
            className="text-capitalize w-100"
            placeholder="Select Status"
            autoComplete="new-off"
            onChange={(value) => handleChange("Status", value)}
            value={values?.Status}
          >
            <Option className="text-capitalize" value="">
              All
            </Option>
            <Option className="text-capitalize" value={"0"}>
              Inactive
            </Option>
            <Option className="text-capitalize" value={"1"}>
              Active
            </Option>
          </Select>
        </Col>
        <Col md={5} sm={12} xs={24}>
          <div className="flex gap10">
            <button className="filter-button" onClick={() => handleFilter()}>
              Filter
            </button>
            <button
              className="clear-button"
              onClick={() => handleFilterReset()}
            >
              Reset
            </button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default LeadPageFilter;
