import React, { useEffect, useState } from "react";
import { Col, Form, Input, notification, Row, Select } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "react-query";
import { GetLoggedInUser } from "../../../utils";
import HeadingBack from "../../../component/HeadingBack";
import { LeadPageServices, BannerServices, CommonServices, TestDepartmentServices } from "../../../redux/Services";
import { usePermission } from "../../../hooks/usePermission";
import FileUpload from "./FileUpload";
import API_URL from "../../../api/url";
const { Option } = Select;

const EditManagePage = () => {
  const permission = usePermission("Lead Page Management", "Edit");
  const { LeadPageId } = useParams();
  var newOptions = [];
  let LOG_IN_USER = GetLoggedInUser();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const LeadPageData = useSelector((state) => state.lead.leadPageDetail || {});
  const cityList = useSelector(state => state.common.cityList || []);
  const { mutate: fetchCities } = useMutation((data) => dispatch(CommonServices.fetchCitiesApi(data)));
  const zoneList = useSelector((state) => state.lead.zoneList || []);
  const { mutate: fetchZoneList } = useMutation((data) =>
    dispatch(LeadPageServices.fetchZoneListApi(data))
  );
  const TestdepartmentList = useSelector(
    (state) => state.Testdepartment.TestdepartmentList || []
  );
  const { mutate: fetchTestDepartmentList } = useMutation((data) =>
    dispatch(TestDepartmentServices.fetchTestDeepartmentListApi(data))
  );
  const { mutate: fetchLeadPageById } = useMutation((data) =>
    dispatch(LeadPageServices.fetchLeadPageDetailByIdApi(data, LeadPageId))
  );
  const { mutate: updateLeadPage } = useMutation((data) =>
    dispatch(LeadPageServices.updateLeadPageApi(data, LeadPageId, navigate))
  );
  const { mutate: BannerImageAdd } = useMutation((data) =>
    dispatch(BannerServices.BannerImageAddApi(data))
  );
  const [file, setFile] = useState("");
  const [relatedTestCode, setRelatedTestCode] = useState("");
  useEffect(() => {
    fetchCities({});
    fetchZoneList({ Status: "1" });
    fetchTestDepartmentList({});
    fetchLeadPageById({ isActive: "1" });
    return () => {};
  }, []);

  useEffect(() => {
    let BannerPath =
    LeadPageData?.BannerPath?.replace(API_URL.S3_BANNER_BASE?.toString(), "") || "";
    if (LeadPageData && Object.keys(LeadPageData)?.length > 0) {
    
      form.setFieldsValue({
        ...LeadPageData,
        BannerPath: BannerPath,
      });
      setFile(BannerPath);
      const zoneIdString = LeadPageData?.ZoneId;
      const zoneIdNumber = +zoneIdString;
      form.setFieldValue("ZoneId",zoneIdNumber);
      form.setFieldValue("PageTestCode", LeadPageData?.PageTestCode);
      form.setFieldValue("PageSuffix", LeadPageData?.PageSuffix);
      form.setFieldValue("PageCities", LeadPageData?.PageCities ? LeadPageData.PageCities.split(',') : []);
      form.setFieldValue("RelatedTestCode", LeadPageData?.RelatedTestCode);
      form.setFieldValue("PriceCityId", LeadPageData?.PriceCityId);
      form.setFieldValue('BannerPath', LeadPageData?.BannerPath);
      form.setFieldValue('Status', LeadPageData?.Status);
    }
    
  }, [LeadPageData]);
  const handleUpload = (file) => {
    const formData = new FormData();
    formData.append("image", file);
    formData.append("dir_location", "lead-pages-new");
    BannerImageAdd(formData, {
      onSuccess: (res) => {
        var fileName = res.data?.data?.key;
        setFile(fileName);
      },
    });
  };
  const handleSubmit = () => {
    form.validateFields().then((values) => {
      if (LeadPageId) {
        let _data = { ...values };
        const { PageTestCode, PageSuffix} = values;
        const zone = zoneList.find((item) => item?.Id === values.ZoneId);
        const zoneName = zone ? zone?.Name : "";
        _data.UserId = LOG_IN_USER?.id;
        if (Array.isArray(values.PageCities)) {
          _data.PageCities = values.PageCities.join(',') || '';
        }
      _data.PageSlug = `${zoneName || ""}/${PageTestCode || ""}/${
        PageSuffix || ""
      }`;
        updateLeadPage(_data);
      }
    });
  };

  const onTestCodeSelect = (value) => {
    const selectedTest = TestdepartmentList.find((item) => item.TestCode === value);
    if (selectedTest) {
      setRelatedTestCode(selectedTest.RelatedTestCode);
      form.setFieldsValue({ RelatedTestCode: selectedTest.RelatedTestCode });
    } else {
      setRelatedTestCode("");
      form.setFieldsValue({ RelatedTestCode: "" });
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      BannerPath: file,
    });
  }, [file]);

  return (
    <>
      <HeadingBack title={"Page Edit"} subHeading="" />
      <div className="p-20">
        <Form form={form} name="basic" layout={"vertical"} autoComplete="off">
          <Row gutter={[24, 0]}>
          <Col lg={7} md={24} sm={24} xs={24}>
              <Form.Item
                label="Zone/Region"
                name="ZoneId"
                rules={[{ required: true, message: "Zone/Region is required" }]}
              >
                <Select
                  className="from-control text-capitalize"
                  placeholder="Select Zone/Region"
                  autoComplete="new-off"                  
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  name="ZoneId"
                >
                  {zoneList &&
                    zoneList?.length > 0 &&
                    zoneList?.map((item, idx) => (
                      <Option
                        key={idx}
                        className="text-capitalize"
                        value={item?.Id}
                      >
                        {item?.Name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={1} md={24} sm={24} xs={24} className="slash-line">
              /
            </Col>
            <Col lg={7} md={24} sm={24} xs={24}>
              <Form.Item
                label="Test Code"
                name="PageTestCode"
                rules={[{ required: true, message: "Test Code is required" }]}
              >
                <Select
                  className="from-control text-capitalize"
                  placeholder="Select Test Code"
                  autoComplete="new-off"                  
                  showSearch
                  onSelect={onTestCodeSelect}
                >
                  {TestdepartmentList &&
                    TestdepartmentList?.length > 0 &&
                    TestdepartmentList?.map((item, idx) => (
                      <Option
                        key={idx}
                        className="text-capitalize"
                        value={item?.TestCode}
                      >
                        {item?.TestCode}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={1} md={24} sm={24} xs={24} className="slash-line">
              /
            </Col>

            <Col lg={8} md={24} sm={24} xs={24}>
              <Form.Item label="Page Suffix" name="PageSuffix" rules={[{ required: true, message: "Page Suffix is required" }]}>
                <Input
                  type="text"
                  autoComplete="new-off"
                  className="form-control"
                  placeholder="Page Suffix"
                />
              </Form.Item>
            </Col>
            <Col lg={12} md={24} sm={24} xs={24}>
              <Form.Item label="Related Test Codes" name="RelatedTestCode" rules={[{ required: true, message: "Related Test Codes are required" }]}>
                <Input
                  type="text"
                  autoComplete="new-off"
                  className="form-control"
                  placeholder="Related Test Codes"
                  value={relatedTestCode}
                  disabled
                />
              </Form.Item>
            </Col>
            <Col lg={12} md={24} sm={24} xs={24}>
              <Form.Item
                label="Price City"
                name="PriceCityId"
                rules={[{ required: true, message: "Price City is required" }]}
              >
                <Select
                  className="from-control text-capitalize"
                  placeholder="Select Price City"
                  autoComplete="new-off"
                  showSearch
                >
                  {cityList &&
                    cityList?.length > 0 &&
                    cityList?.map((item, idx) => (
                      <Option
                        key={idx}
                        className="text-capitalize"
                        value={item?.city_name}
                      >
                        {item?.city_name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={12} md={24} sm={24} xs={24}>
              <Form.Item
                label="Cities"
                name="PageCities"
                rules={[{ required: true, message: "Cities is required" }]}
              >
                <Select
                  mode="multiple"
                  className="from-control text-capitalize"
                  placeholder="Select Cities"
                  autoComplete="new-off"
                >
                  {cityList &&
                    cityList?.length > 0 &&
                    cityList?.map((item, idx) => (
                      <Option
                        key={idx}
                        className="text-capitalize"
                        value={item?.city_name}
                      >
                        {item?.city_name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={12} md={24} sm={24} xs={24}>
              <Form.Item
                label="Status"
                name="Status"
                rules={[{ required: true, message: "Status is required" }]}
              >
                <Select
                  className="from-control text-capitalize"
                  placeholder="Select Status"
                  autoComplete="new-off"
                  name="Status"
                >
                  <Option className="text-capitalize" value={0}>
                    Inactive
                  </Option>
                  <Option className="text-capitalize" value={1}>
                    Active
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col lg={12} md={24} sm={24} xs={24}>
              <Form.Item
                label="Banner Image"
                name="BannerPath"
                rules={[
                  { required: true, message: "Banner Image is required" },
                ]}
              >
                <FileUpload
                  file={file}
                  setFile={setFile}
                  handleUpload={handleUpload}
                />
                 <div className='recom-t'>
                                    <p>Accept webp Only</p>
                                    <p>Recommended Size:1920*622</p>
                                    <p>Maximum Resolution should be 300kb</p>
                                </div>
              </Form.Item>
            </Col>
          </Row>
          <div className="text-center">
            <button
              key="submit"
              type="submit"
              className="gradient-button w-auto"
              onClick={handleSubmit}
            >
              Update
            </button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default EditManagePage;
