import React, { useEffect, useState } from "react";
import { Col, Form, Input, Row, Select, AutoComplete } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "react-query";
import { GetLoggedInUser } from "../../../utils";
import { useNavigate } from "react-router-dom";
import HeadingBack from "../../../component/HeadingBack";
import FileUpload from "./FileUpload";
import {
  CommonServices,
  BannerServices,
  LeadPageServices,
  TestDepartmentServices
} from "../../../redux/Services";
import { usePermission } from "../../../hooks/usePermission";

const { Option } = Select;
const AddManagePage = () => {
  const permission = usePermission("Lead Page Management", "Add");
  var newOptions = [];
  const [referenceId, setReferenceId] = useState("");
  const [value, setValue] = useState("");
  const [file, setFile] = useState("");
  let LOG_IN_USER = GetLoggedInUser();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const TestdepartmentList = useSelector(
    (state) => state.Testdepartment.TestdepartmentList || []
  );
  const cityList = useSelector((state) => state.common.cityList || []);
  const { mutate: fetchCities } = useMutation((data) =>
    dispatch(CommonServices.fetchCitiesApi(data))
  );
  const zoneList = useSelector((state) => state.lead.zoneList || []);
  const { mutate: fetchZoneList } = useMutation((data) =>
    dispatch(LeadPageServices.fetchZoneListApi(data))
  );
  const { mutate: addLeadPage } = useMutation((data) =>
    dispatch(LeadPageServices.addLeadPageApi(data, navigate))
  );
  const { mutate: BannerImageAdd } = useMutation((data) =>
    dispatch(BannerServices.BannerImageAddApi(data))
  );
  const { mutate: fetchTestDepartmentList } = useMutation((data) =>
    dispatch(TestDepartmentServices.fetchTestDeepartmentListApi(data))
  );
  const [options, setOptions] = useState([]);
  const [relatedTestCode, setRelatedTestCode] = useState("");
  useEffect(() => {
    fetchCities({});
    fetchZoneList({ Status: "1" });
    fetchTestDepartmentList({});
    // fetchCouponCategory({ isActive: "1" });
    return () => {};
  }, []);
  const handleUpload = (file) => {
    const formData = new FormData();
    formData.append("image", file);
    formData.append("dir_location", "lead-pages-new");
    BannerImageAdd(formData, {
      onSuccess: (res) => {
        var fileName = res.data?.data?.key;
        setFile(fileName);
      },
    });
  };
  useEffect(() => {
    form.setFieldsValue({
      BannerPath: file,
    });
  }, [file]);
  const handleSubmit = () => {
    form.validateFields().then((values) => {
      let _data = { ...values };
      _data.UserId = LOG_IN_USER?.id;
      if (values.PageCities) {
        _data.PageCities = values.PageCities.join(',');
    }
      const zone = zoneList.find((item) => item?.Id === values.ZoneId);
      const zoneName = zone ? zone.Name : "";
      const { PageTestCode, PageSuffix , PageCities} = values;
      _data.PageCities = PageCities ? PageCities.join(',') : '';
      _data.PageSlug = `${zoneName || ""}/${PageTestCode || ""}/${
        PageSuffix || ""
      }`;

      addLeadPage(_data);
    });
  };
  const onInputChange = (value) => {
    if (value && value.length >= 2) {
      fetchTestDepartmentList({ TestCode: value });
      // setValue(value);
    }
  };
  const handleOnSelect = (item) => {
    console.log("item", item);
    setReferenceId(item);
  };

  function handleOnFocus() {
    setReferenceId(null);
  }
  const onTestCodeSelect = (value) => {
    const selectedTest = TestdepartmentList.find((item) => item.TestCode === value);
    if (selectedTest) {
      setRelatedTestCode(selectedTest.RelatedTestCode);
      form.setFieldsValue({ RelatedTestCode: selectedTest.RelatedTestCode });
    } else {
      setRelatedTestCode("");
      form.setFieldsValue({ RelatedTestCode: "" });
    }
  };
  useEffect(() => {
    newOptions = [];
    TestdepartmentList.map((value, key) => {
      newOptions.push({
        value: value.TestCode,
      });
    });
    setOptions(newOptions);
    return () => {};
  }, [TestdepartmentList]);


  return (
    <>
      <HeadingBack title={"Page Add"} subHeading="" />
      <div className="p-20">
        <Form
          form={form}
          name="basic"
          layout={"vertical"}
          autoComplete="off"
          initialValues={{ Status: "1" }}
        >
          <Row gutter={[24, 0]}>
            <Col lg={7} md={24} sm={24} xs={24}>
              <Form.Item
                label="Zone/Region"
                name="ZoneId"
                rules={[
                  { required: true, message: "Zone/Region is required" },
                ]}
              >
                <Select
                  className="from-control text-capitalize"
                  placeholder="Select Zone/Region"
                  autoComplete="new-off"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {zoneList &&
                    zoneList?.length > 0 &&
                    zoneList?.map((item) => (
                      <Option
                        key={item?.Id}
                        className="text-capitalize"
                        value={item?.Id}
                      >
                        {item?.Name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={1} md={24} sm={24} xs={24} className="slash-line">
              /
            </Col>
            <Col lg={7} md={24} sm={24} xs={24}>
              <Form.Item
                label="Test Code"
                name="PageTestCode"
                rules={[{ required: true, message: "Test Code is required" }]}
              >
                <Select
                  className="from-control text-capitalize"
                  placeholder="Select Test Code"
                  autoComplete="new-off"
                  showSearch
                  onSelect={onTestCodeSelect}
                >
                  {TestdepartmentList &&
                    TestdepartmentList?.length > 0 &&
                    TestdepartmentList?.map((item, idx) => (
                      <Option
                        key={idx}
                        className="text-capitalize"
                        value={item?.TestCode}
                      >
                        {item?.TestCode}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={1} md={24} sm={24} xs={24} className="slash-line">
              /
            </Col>

            <Col lg={8} md={24} sm={24} xs={24}>
              <Form.Item label="Page Suffix" name="PageSuffix" 
              rules={[
                  { required: true, message: "Page Suffix" },
                ]}>
                <Input
                  type="text"
                  autoComplete="new-off"
                  className="form-control"
                  placeholder="Page Suffix"
                />
              </Form.Item>
            </Col>
            <Col lg={12} md={24} sm={24} xs={24}>
              <Form.Item label="Related Test Codes" name="RelatedTestCode" rules={[{ required: true, message: "Related Test Codes are required" }]}>
                <Input
                  type="text"
                  autoComplete="new-off"
                  className="form-control"
                  placeholder="Related Test Codes"
                  value={relatedTestCode}
                  disabled
                />
              </Form.Item>
            </Col>
            <Col lg={12} md={24} sm={24} xs={24}>
              <Form.Item
                label="Price City"
                name="PriceCityId"
                rules={[{ required: true, message: "Price City is required" }]}
              >
                <Select
                  className="from-control text-capitalize"
                  placeholder="Select Price City"
                  autoComplete="new-off"
                  showSearch
                >
                  {cityList &&
                    cityList?.length > 0 &&
                    cityList?.map((item, idx) => (
                      <Option
                        key={idx}
                        className="text-capitalize"
                        value={item?.city_name}
                      >
                        {item?.city_name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={12} md={24} sm={24} xs={24}>
              <Form.Item
                label="Cities"
                name="PageCities"
                rules={[{ required: true, message: "Cities is required" }]}
              >
                <Select
                  mode="multiple"
                  className="from-control text-capitalize"
                  placeholder="Select Cities"
                  autoComplete="new-off"
                >
                  {cityList &&
                    cityList?.length > 0 &&
                    cityList?.map((item, idx) => (
                      <Option
                        key={idx}
                        className="text-capitalize"
                        value={item?.city_name}
                      >
                        {item?.city_name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={12} md={24} sm={24} xs={24}>
              <Form.Item
                label="Status"
                name="Status"
                rules={[{ required: true, message: "Status is required" }]}
              >
                <Select
                  className="from-control text-capitalize"
                  placeholder="Select Status"
                  autoComplete="new-off"
                  name="Status"
                >
                  <Option className="text-capitalize" value={"0"}>
                    Inactive
                  </Option>
                  <Option className="text-capitalize" value={"1"}>
                    Active
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col lg={12} md={24} sm={24} xs={24}>
              <Form.Item
                label="Banner Image"
                name="BannerPath"
                rules={[
                  { required: true, message: "Banner Image is required" },
                ]}
              >
                <FileUpload
                  file={file}
                  setFile={setFile}
                  handleUpload={handleUpload}
                />
                <div className="recom-t">
                  <p>Accept webp Only</p>
                  <p>Recommended Size:1920*622</p>
                  <p>Maximum Resolution should be 300kb</p>
                </div>
              </Form.Item>
            </Col>
          </Row>
          <div className="text-center">
            <button
              key="submit"
              type="submit"
              className="gradient-button w-auto"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default AddManagePage;
